import "./styles.css";
import Header from "./components/header";
import Footer from "./components/footer";
import Home from "./pages/home";
import Stock from "./pages/stock";
import News from "./pages/news";
import Contact from "./pages/contact";
import BussinesServices from "./pages/bussinesServices";
import ClientServices from "./pages/clientServices";
import Sale from "./pages/sale";
import About from "./pages/about";
import Pay from "./pages/pay";
import PageConstruction from "./components/pageConstruction";
import Investments from "./pages/investments";
import {Route, Switch} from "react-router-dom";

export default function App() {
  return (
    <div className="App">
      <Header />
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/client" component={ClientServices} />
        <Route path="/bussines" component={BussinesServices} />
        <Route path="/sale" component={Sale} />
        {/* <Route path="/news" component={News} /> */}
        <Route path="/contact" component={Contact} />
        <Route path="/about" component={About} />
        <Route path="/page-construction" component={PageConstruction} />
      </Switch>
      <Footer />
    </div>
  );
}
