import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { Link } from "react-router-dom";



const useStyles = makeStyles((theme) => ({
    contentItem: {
        margin: "20px",
        "&:hover": {
            boxShadow: "1px 1px 12px 0px rgb(72 77 98 / 17%)"
        },
        [theme.breakpoints.down("xs")]: {
            // minWidth: "160px"
        }
    },
    contentItem_p: {
        width: "200px",
        height: "100%",
        padding: "30px",
        boxSizing: "border-box",
        border: "1px solid #F3F4F4",
        [theme.breakpoints.down("xs")]: {
            // width: "100%",
            fontSize: "1.3rem",
        }
    },
    contentItem__icon: {
        padding: "20px"
    }
}));

export default function ClientServicesItem({ svg, text, link }) {
    const classes = useStyles();
    return (
        <Grid item className={classes.contentItem}>
            {link ?
                <Link to={link}>
                    <Paper elevation={0} className={classes.contentItem_p}>
                        <div className={classes.contentItem__icon}>{svg}</div>
                        <p>{text}</p>
                    </Paper>
                </Link>
                :
                <Paper elevation={0} className={classes.contentItem_p}>
                    <div className={classes.contentItem__icon}>{svg}</div>
                    <p>{text}</p>
                </Paper>}
        </Grid>
    );
}
