import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ButtonComponent from "../../components/button";
import ReferenceContentItem from "../servicesClient/components/referenceItem";
import {ReactComponent as PayManIcon} from "../../static/svg/payMan.svg";
import {ReactComponent as PayRefIcon} from "../../static/svg/payRef.svg";
import {ReactComponent as AccountIcon} from "../../static/svg/account2.svg";
import {options} from "../../config";

const useStyles = makeStyles((theme) => ({
  main: {
    // textAlign: "left"
  },
  mainTitle: {
    padding: "10px",
    background: "#F3F4F4",
    color: "#484d62",
    margin: "20px 0px 10px 0px",
    textAlign: "center"
  },
  mainItem: {
    margin: "10px"
  },
  mainDescription: {
    margin: "20px auto"
  },
  list: {
    padding: "20px"
  },
  titleBlock: {
    color: "#484D62",
    fontSize: "2rem",
    fontFamily: "Segoe UI, sans-serif",
    fontWeight: "bold",
    padding: "0px 0px 30px 20px"
  },
  linkButton: {
    width: "100%",
    display: "block",
    textAlign: "center"
  }
}));
export default function ReferenceContent({title, url, img2}) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const {telephone} = options;

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Grid item container className={classes.main}>
        <Grid item xs className={classes.mainItem}>
          <PayManIcon height="200px" />
          <Typography className={classes.mainTitle}>Справка об отсутствии задолженности</Typography>
          <ButtonComponent
            fullWidth
            title={<a href={`tel:${telephone.main}`} className={classes.linkButton}>Позвонить</a>}
          />
          <Typography align="left" className={classes.mainDescription}>
            Справка выдается после полного погашения задолженности. Документ
            можно запросить по телефону: {telephone.main}
          </Typography>
        </Grid>
        <Grid item xs className={classes.mainItem}>
          <PayManIcon height="200px" />
          <Typography className={classes.mainTitle}>Запросить график погашения</Typography>
          <ButtonComponent fullWidth title={<a href={`tel:${telephone.main}`} className={classes.linkButton}>Позвонить</a>} />
          <Typography align="left" className={classes.mainDescription}>
            Узнать график платежей, а так же получить справку о остатке
            задолженности.
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}
