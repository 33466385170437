import React from "react";
import BlockContent from "../components/blockContent";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  main: {
    textAlign: "left"
  },
  title: {
    fontSize: "5rem",
    [theme.breakpoints.down("xs")]: {
      fontSize: "3rem"
    }
  },
  content: {
    backgroundColor: "#F3F4F4",
    borderTop: "1px solid #D0D7DD"
  },
  blockImg: {
    border: "1px solid rgb(243 244 244)",
    textAlign: "center",
    borderRadius: "7px",
    background: "#f3f4f4",
    padding: "20px",
    margin: "20px"
  }
}));

export default function About() {
  const classes = useStyles();
  return (
    <>
      <BlockContent title="О компании" bigFont>
        <Grid container className={classes.main}>
          <Grid item>
            <Typography gutterBottom component="p">
              Наше агентство является надежным и эффективным инструментом
              взыскания проблемных задолженностей с физических и юридических
              лиц, решая широкий спектр правовых вопросов по экономическим
              правоотношениям.
            </Typography>
            <Typography gutterBottom component="p">
              Вы можете быть уверены в том, что при обращении к нам Ваша деловая
              репутация не пострадает. Кроме того, Вы избавитесь от
              необходимости общаться с недобросовестными должниками, а так же
              посещать судебные заседания и приставов-исполнителей.
            </Typography>
            <Typography gutterBottom component="p">
              Мы всегда действуем по отношению к должникам корректно и в рамках
              закона, сохраняя полную конфиденциальность получаемой информации.
            </Typography>
            <Typography gutterBottom component="p">
              Наше агентство использует индивидуальный подход к каждой ситуации,
              стараясь разрешить её в досудебном порядке. Если же этого не
              избежать, то наши специалисты сопровождают дела должников в суде и
              во время исполнительного производства, взаимодействуя с
              государственными органами и контролируя ход событий.
            </Typography>
            <Typography gutterBottom component="p">
              Наши клиенты платят только за результат, что само по себе является
              веским доказательством профессионализма наших сотрудников.
            </Typography>
            <Typography gutterBottom component="p">
              Обращайтесь! Ваши деньги должны быть у Вас!
            </Typography>
          </Grid>
        </Grid>
      </BlockContent>
    </>
  );
}
