import React, { useState } from "react";
import BorderForComponent from "../components/borderForComponent";
import ModalInvestInput from "../components/investFrom";
import InvestmentsForm from "../components/investmentsFrom";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  main: {
    padding: "50px 0px 50px 0px",
    boxSizing: "border-box",
    // [theme.breakpoints.down("xs")]: {
    //   padding: "0px 15px 0px 15px"
    // }
    [theme.breakpoints.down("xs")]: {
      padding: "30px 0px 30px 0px"
    }
  },
  text: {
    padding: "10px",
    [theme.breakpoints.down("xs")]: {
      padding: "0px 15px 0px 15px"
    }
  },
  title: {
    fontSize: "4rem",
    paddingBottom: "20px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "3rem"
    }
  }
}));
export default function Investments() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState({
    month: 12,
    sum: 500000,
    interest: "monthly",
    interestRate: 16,
    monthlyIncome: 0,
    totalIncome: 0
  });
  const handleChange = (e) => {
    setOptions({ ...options, [e.target.name]: e.target.value });
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <ModalInvestInput
        open={open}
        title="Отправить заявку"
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
        dataOptions={options}
      />
      <div className={classes.main}>
        <div className={classes.text}>
          <h2 className={classes.title}>Расчитайте свой доход</h2>
          <h3>Инвестируйте в быстрорастущий бизнес с инновационными технологиями</h3>
        </div>
        <BorderForComponent>
          <InvestmentsForm handleClickOpen={handleClickOpen} handleChange={handleChange} setOptions={setOptions} options={options} />
        </BorderForComponent>
      </div>
    </>
  );
}
