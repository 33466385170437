import React from "react";
import BlockContent from "../components/blockContent";
import ButtonComponent from "../components/button";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {ReactComponent as LocationIcon} from "../static/svg/location.svg";
import {ReactComponent as CallCentreIcon} from "../static/svg/call-centre.svg";
import {ReactComponent as EmailBorder} from "../static/svg/emailBorder.svg";
import {options} from "../config";

const useStyles = makeStyles((theme) => ({
  main: {
    // textAlign: "left"
  },
  mainTitle: {
    padding: "10px",
    background: "#F3F4F4",
    color: "#484d62",
    margin: "20px 0px 10px 0px",
    textAlign: "center"
  },
  mainItem: {
    margin: "10px"
  },
  mainDescription: {
    margin: "20px auto"
  },
  list: {
    padding: "20px"
  },
  titleBlock: {
    color: "#484D62",
    fontSize: "2rem",
    fontFamily: "Segoe UI, sans-serif",
    fontWeight: "bold",
    padding: "0px 0px 30px 20px"
  },
  title: {
    fontSize: "5rem",
    [theme.breakpoints.down("xs")]: {
      fontSize: "3rem"
    }
  },
  content: {
    backgroundColor: "#F3F4F4",
    borderTop: "1px solid #D0D7DD"
  },
  blockImg: {
    border: "1px solid rgb(243 244 244)",
    textAlign: "center",
    borderRadius: "7px",
    background: "#f3f4f4",
    padding: "20px",
    margin: "20px"
  },
  linkButton: {
    width: "100%",
    display: "block",
    textAlign: "center"
  }
}));

export default function Contact() {
  const classes = useStyles();
  const {email, telephone, legal_address} = options;
  return (
    <BlockContent title="Контакты" bigFont>
      <Grid container className={classes.main}>
        <Grid item xs={12} sm className={classes.mainItem}>
          <LocationIcon height="100px" />
          <Typography className={classes.mainTitle}>Адрес</Typography>
          <ButtonComponent
            fullWidth
            title="ПН-ПТ с 9-00 до 17-00 "
          />
          <Typography align="left" className={classes.mainDescription}>
            {legal_address}
          </Typography>
        </Grid>
        <Grid item xs className={classes.mainItem}>
          <EmailBorder height="100px" />
          <Typography className={classes.mainTitle}>
            Email: {email}
          </Typography>
          <ButtonComponent
            fullWidth
            title={<a href={`mailto:${email}`} className={classes.linkButton}>Написать</a>}
          />
          <Typography align="left" className={classes.mainDescription}>
            Пишите нам на электронную почту, мы всегда рады ответить на ваши
            вопросы.
          </Typography>
        </Grid>
        <Grid item xs={12} sm className={classes.mainItem}>
          <CallCentreIcon height="100px" />
          {telephone.additional === "null"
            ?
            <Typography className={classes.mainTitle}>
              {telephone.main}
            </Typography>
            :
            <Typography className={classes.mainTitle}>
              {telephone.main} | {telephone.additional}
            </Typography>
          }
          <ButtonComponent
            fullWidth
            title={<a href={`tel:${telephone.main}`} className={classes.linkButton}>Позвонить</a>}
          />
          <Typography align="left" className={classes.mainDescription}>
            Позвонив нам, Вы услышите очень вежливого и приятного собеседника,
            который ответит вам на Ваши вопросы.
          </Typography>
        </Grid>
      </Grid>
    </BlockContent>
  );
}
