export const servicesList = [
  {
    id: 0,
    name: "Узнать свою задолженность",
    url: "debt",
    img2: "searchIcon.svg",
    img: "apartments3.svg",
    description: "ЛЯЛЯ"
  },
  {
    id: 1,
    name: "Узнать сумму долга к прощению",
    url: "stock",
    img2: "saleIcon.svg",
    img: "apartments3.svg",
    description: "ЛЯЛЯ"
  },
  {
    id: 3,
    name: "Получить справку",
    url: "reference",
    img2: "reference.svg",
    img: "apartments3.svg",
    description: "ЛЯЛЯ"
  },
  {
    id: 4,
    name: "Консультация",
    url: "consultation",
    img2: "phone.svg",
    img: "apartments3.svg",
    description: "ЛЯЛЯ"
  },
  {
    id: 5,
    name: "Юридические услуги",
    url: "legalServices",
    img2: "legal_services.svg",
    img: "apartments3.svg",
    description: "ЛЯЛЯ"
  },
  {
    id: 6,
    name: "Досудебное урегулирование",
    url: "preTrial",
    img2: "handshake.svg",
    img: "apartments3.svg",
    description: "ЛЯЛЯ"
  }
];
