import React from "react";
import ButtonComponent from "./button";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { ReactComponent as SaleBirtIcon } from "../static/svg/sale_birthday.svg";

const useStyles = makeStyles((theme) => ({
  content: {
    backgroundColor: "#F3F4F4",
    borderTop: "1px solid #D0D7DD"
  },
  blockImg: {
    width: "100%"
  },
  contentItem: {
    padding: "20px",

    "&:hover": {
      // margin: "1px",
    }
  },
  contentItem_p: {
    width: "250px",
    height: "100%",
    padding: "15px",
    boxSizing: "border-box",
    textAlign: "left",
    backgroundColor: "#F3F4F4",
    display: "flex",
    alignItems: "baseline"
  },
  contentItem_p_text: {
    padding: "15px 0px 15px 0px;"
  },
  contentItem_description: {
    padding: "20px 0px 10px 0px",
    // height: "100px",
    flexGrow: "1",
    [theme.breakpoints.down("xs")]: {
      // height: "85px"
    }
  },
  contentItem_d_t: {
    textDecoration: "underline",
    color: "#484d62",
    fontWeight: "bold"
  },
  contentItem_button: {
    margin: "20px 0px 20px 0px",
    boxShadow: "1px 1px 15px 0px #e1c544"
  }
}));

export default function StockItem({ title, text, img, handleClickOpen }) {
  const classes = useStyles();
  return (
    <Grid item sm xs={12} className={classes.contentItem}>
      <Grid direction="column" className={classes.contentItem_p}>
        <SaleBirtIcon className={classes.blockImg} />
        <div className={classes.contentItem_description}>
          <Typography variant="h5" className={classes.contentItem_d_t}>
            {title}
          </Typography>
          <Typography
            variant="subtitle2"
            component="p"
            className={classes.contentItem_p_text}
          >
            {text}
          </Typography>
        </div>
        <ButtonComponent
          title="Принять участие"
          className={classes.contentItem_button}
          onClick={handleClickOpen}
        />
      </Grid>
    </Grid>
  );
}
