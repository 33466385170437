import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import PhoneIcon from "@material-ui/icons/Phone";
import MailOutlineIcon from "@material-ui/icons/MailOutline";

import { options } from "../config";

const useStyles = makeStyles((theme) => ({
  title: {
    margin: "0px 0px 0px 7px"
  },
  blockContact: {
    marginRight: "20px",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    flexDirection: "column",
    [theme.breakpoints.down("xs")]: {
      display: "none"
    }
  },
  gridItemContact: {
    flexWrap: "nowrap",
    alignItems: "center"
  }
}));

export default function ContactData() {
  const classes = useStyles();
  const { email, telephone } = options;
  return (
    <Grid item className={classes.blockContact}>
      <Grid item xs container className={classes.gridItemContact}>
        <PhoneIcon fontSize="small" />
        <Typography noWrap variant="body2" className={classes.title}>
          {telephone.main}
        </Typography>
      </Grid>
      {telephone.additional === "null"
        ?
        null
        :
        <Grid item xs container className={classes.gridItemContact}>
          <PhoneIcon fontSize="small" />
          <Typography noWrap variant="body2" className={classes.title}>
            {telephone.additional}
          </Typography>
        </Grid>
      }
      <Grid item xs container className={classes.gridItemContact}>
        <MailOutlineIcon fontSize="small" />
        <Typography noWrap variant="body2" className={classes.title}>
          {email}
        </Typography>
      </Grid>
    </Grid>
  );
}
