import React from "react";
import DebtContent from "./servicesClient/debt";
import AdvantagesForClients from "../components/ourAdvantagesForClients";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {Link, useRouteMatch} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  content: {
    // backgroundColor: "#F3F4F4",
    borderTop: "1px solid #D0D7DD"
  },
  main: {
    textAlign: "left"
  },
  mainTitle: {
    padding: "10px",
    background: "#F3F4F4",
    color: "#484d62",
    margin: "20px 0px 10px 0px",
    textAlign: "center"
  },
  list: {
    padding: "20px"
  },
  contentItem: {
    padding: "20px",
    width: "250px",
    "&:hover": {
      // margin: "1px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "160px"
    }
  },
  contentItem_p: {
    height: "80%",
    padding: "15px",
    boxSizing: "border-box",
    border: "1px solid #F3F4F4"
  },
  titleBlock: {
    color: "#484D62",
    fontSize: "2rem",
    fontFamily: "Segoe UI, sans-serif",
    fontWeight: "bold",
    padding: "0px 0px 30px 20px"
  }
}));
export default function ClientServicesItem({title, url, componentContent}) {
  const classes = useStyles();

  return (
    <>
      <Typography variant="h5" component="h1">
        {title}
      </Typography>
      {componentContent}
      <AdvantagesForClients />
    </>
  );
}
