import React from "react";
import AppBarContainer from "./appBarContainer";
import FormCallback from "./formCallback";
import BottomNavigationMobile from "./bottomNavigation";
import Modal from "@material-ui/core/Modal";
import Hidden from "@material-ui/core/Hidden";

export default function Header() {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <AppBarContainer />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <FormCallback />
      </Modal>
      <Hidden smUp>
        <BottomNavigationMobile />
      </Hidden>
    </>
  );
}
