import React from "react";
import BorderForComponent from "./borderForComponent";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  gridList: {
    flexWrap: "nowrap",
    transform: "translateZ(0)",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      height: "10px"
      // width: "30px"
    },
    "&::-webkit-scrollbar-thumb": {
      background: "rgba(40, 86, 146, 0.5)",
      height: "10px",
      borderRadius: "10px"
    }
  },
  content: {
    width: "100%"
  },
  gridBlockStyle: {
    // background: "#ffffff",
    padding: "70px 0px 70px 0px",
    // borderRadius: "5px",
    // marginBottom: "50px",
    // marginTop: "50px",
    [theme.breakpoints.down("xs")]: {
      padding: "40px 0px 40px 0px"
    }
  },
  title: {
    fontSize: "2rem",
    fontFamily: "Segoe UI, sans-serif",
    paddingBottom: "30px",
    fontWeight: "bold"
  },
  titleSize: {
    fontSize: "4rem",
    [theme.breakpoints.down("xs")]: {
      fontSize: "3rem"
    }
  },
  description: {
    fontSize: "1.2rem",
    textAlign: "left",
    fontFamily: "Segoe UI, sans-serif",
    paddingBottom: "30px",
    fontWeight: "400"
  }
}));

export default function BlockContent({
  title,
  bigFont,
  children,
  className,
  classNameChildren,
  description
}) {
  const classes = useStyles();
  return (
    <Grid className={clsx(classes.gridBlockStyle, className)}>
      <BorderForComponent>
        <div className={classes.content}>
          {title && (
            <Typography
              className={clsx(classes.title, bigFont && classes.titleSize)}
            >
              {title}
            </Typography>
          )}
          {description && (
            <Typography className={classes.description}>
              {description}
            </Typography>
          )}
          <Grid container className={clsx(classes.gridList, classNameChildren)}>
            {children}
          </Grid>
        </div>
      </BorderForComponent>
    </Grid>
  );
}
