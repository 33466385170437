import React from "react";
import ClientServicesItem from "./clientServicesItem";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { Link, useRouteMatch } from "react-router-dom";
import { ReactComponent as CalenderIcon } from "../static/svg/calenderIcon.svg";
import { ReactComponent as SaleIcon } from "../static/svg/saleIcon.svg";
import { ReactComponent as SearchIcon } from "../static/svg/searchIcon.svg";
import { ReactComponent as Handshake } from "../static/svg/handshake.svg";
import { ReactComponent as LegalServices } from "../static/svg/legal_services.svg";
import { ReactComponent as Phone } from "../static/svg/phone.svg";
import { ReactComponent as Reference } from "../static/svg/reference.svg";

const useStyles = makeStyles((theme) => ({
    content: {
        // backgroundColor: "#F3F4F4",
        borderTop: "1px solid #D0D7DD"
    },
    contentItem: {
        margin: "20px",
        "&:hover": {
            boxShadow: "1px 1px 12px 0px rgb(72 77 98 / 17%)"
        },
        [theme.breakpoints.down("xs")]: {
            // minWidth: "160px"
        }
    },
    contentItem_p: {
        width: "200px",
        height: "100%",
        padding: "30px",
        boxSizing: "border-box",
        border: "1px solid #F3F4F4"
    },
    contentItem__icon: {
        padding: "20px"
    }
}));

export default function ClientAllServices() {
    const classes = useStyles();
    return (
        <>
            <ClientServicesItem link="/client/debt" text="Узнать свою задолженность" svg={<SearchIcon width="100%" />} />
            <ClientServicesItem link="/client/stock" text="Узнать сумму долга к прощению" svg={<SaleIcon width="100%" />} />
            <ClientServicesItem link="/client/reference" text="Получить справку" svg={<Reference width="100%" />} />
            <ClientServicesItem link="/client/consultation" text="Консультация" svg={<Phone width="100%" />} />
            <ClientServicesItem link="/client/legalServices" text="Юридические услуги" svg={<LegalServices width="100%" />} />
            <ClientServicesItem link="/client/preTrial" text="Досудебное регулирование" svg={<Handshake width="100%" />} />
        </>
    );
}
