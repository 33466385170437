import React from "react";
import ContactData from "./contactData";
import BorderForComponent from "./borderForComponent";
import Menu from "./menu";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import CallIcon from '@material-ui/icons/Call';
import { options } from "../config";

const useStyles = makeStyles((theme) => ({
  containerPadding: {
    padding: "10px 0px 10px 0px"
  },
  blockInformation: {
    color: "#F3F4F4",
    background: "#484D62",
    borderBottom: "1px solid rgba(28, 52, 89, 0.41)"
  },
  gridItemContact: {
    flexWrap: "nowrap",
    // padding: "10px",
    alignItems: "center"
  },
  svgSize: {
    fontSize: "9.5rem",
    height: "0.5em",
    [theme.breakpoints.down("xs")]: {
      fontSize: "10rem"
    }
  },
  blockMenuMobile: {
    display: "none",
    [theme.breakpoints.down("xs")]: {
      display: "block",
      textAlign: "right"
    }
  },
  buttonMarg: {
    marginRight: "20px"
  },
  buttonText: {
    maxWidth: "150px",
    lineHeight: "1rem",
    [theme.breakpoints.down("xs")]: {
      display: "none"
    }
  },
  buttonAccount: {
    backgroundColor: "#d5d5d5",
    "&:hover": {
      backgroundColor: "#c6c3ba"
    },
    [theme.breakpoints.down("xs")]: {
      display: "none"
    }
  },
  classNameTitle: {
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  nameCompany: {
    fontFamily: "Prosto One",
    fontSize: "2.5rem",
    color: "#F3F4F4",
    fontWeight: "100",
    letterSpacing: "0.2rem"
  },
  nameCompany_ka: {
    textAlign: "left",
    fontFamily: "Open Sans",
    fontSize: "0.61rem",
    color: "#F3F4F4",
    // fontWeight: "900",
    textTransform: "uppercase"
  }
}));

export default function AppBarContainer() {
  const classes = useStyles();
  const { telephone } = options;
  return (
    <AppBar position="static" elevation={0} color="transparent">
      <div className={classes.blockInformation}>
        <BorderForComponent>
          <Grid
            item
            xs
            container
            justify="space-between"
            alignItems="center"
            className={classes.containerPadding}
          >
            <Grid item xs container alignItems="center" wrap="nowrap">
              <Link to="/">
                <h1 className={classes.nameCompany}>Хитен</h1>
                <h2 className={classes.nameCompany_ka}>Коллекторское агентство</h2>
              </Link>
            </Grid>
            <ContactData />
            {/* <Grid
              item
              className={clsx(classes.gridItemContact, classes.buttonMarg)}
            >
              <Link to="page-construction">
                <ButtonComponent
                  className={classes.buttonText}
                  classNameTitle={classes.classNameTitle}
                  title="Узнать долг и оплатить"
                  startIcon={<PayIcon width="2rem" height="2rem" />}
                />
              </Link>
            </Grid>
            <Grid item className={classes.gridItemContact}>
              <Link to="page-construction">
                <ButtonComponent
                  className={classes.buttonAccount}
                  classNameTitle={classes.classNameTitle}
                  title="Личный кабинет"
                  startIcon={<AccountIcon width="2rem" height="2rem" />}
                />
              </Link>
            </Grid> */}
          </Grid>
          <Grid item xs className={classes.blockMenuMobile}>

            <a href={`tel:${telephone.main}`} style={{ color: "white" }}>
              <CallIcon large />
            </a>
          </Grid>
        </BorderForComponent>
      </div>
      <Menu />
    </AppBar>
  );
}
