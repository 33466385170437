import React from "react";
import {options} from "../config";
import BlockContent from "./blockContent";
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import SvgIcon from "@material-ui/core/SvgIcon";
import Button from "@material-ui/core/Button";
import {Link} from "react-router-dom";
import {ReactComponent as LogoIcon} from "../static/logo-light.svg";
import PolicyPdf from '../static/pdf/policy_1v.pdf';

const useStyles = makeStyles((theme) => ({
  main: {
    background: "#484D62"
  },
  footer: {
    color: "#FFFFFF",
    fontSize: "0.85rem",
    textAlign: "left",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
      marginBottom: "30px"
    }
  },
  divider: {
    width: "100%",
    backgroundColor: "rgba(255, 255, 255, 0.25)"
  },
  buttonOrandge: {
    backgroundColor: "#DB6825",
    color: "#EBF0F5",
    fontSize: "12px",
    height: "40px"
  },
  gridItem: {
    padding: "20px"
  },
  gridItemHidden: {
    [theme.breakpoints.down("xs")]: {
      display: "none"
    }
  },
  svgSize: {
    fontSize: "19.5rem",
    height: "0.5em",
    padding: "20px",
    boxSizing: "border-box",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      fontSize: "20.5rem"
    }
  },
  gridItem_p: {
    marginBottom: "10px"
  },
  gridItemMobile: {
    display: "none",
    [theme.breakpoints.down("xs")]: {
      display: "block",
      width: "100%",
      textAlign: "center",
      margin: "10px"
    }
  },
  button: {
    padding: "0px 20px 0px 20px"
  },
  link: {
    color: '#FFFFFF',
    textDecoration: 'underline',
  }
}));

export default function Footer() {
  const classes = useStyles();
  const {year_birth, abbreviated_name, legal_address, inn, kpp, ogrn, email, telephone} = options;
  return (
    <BlockContent className={classes.main}>
      <Grid container alignItems="center" className={classes.footer}>
        <Grid
          item
          xs={8}
          className={clsx(classes.gridItem, classes.gridItemHidden)}
        >
          <Grid item xs={12} container direction="column" className={classes.gridItem_p}>
            <Typography variant="caption">Адрес</Typography>
            <Typography>
              {legal_address}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            container
            direction="column"
            className={classes.gridItem_p}
          >
            <Typography variant="caption">Телефон</Typography>
            <Typography>{telephone.main}</Typography>
            {telephone.additional === "null" ?
              null
              :
              <Typography>{telephone.additional}</Typography>
            }

          </Grid>
          <Grid
            item
            xs={12}
            container
            direction="column"
            className={classes.gridItem_p}
          >
            <Typography variant="caption">E-mail</Typography>
            <Typography>{email}</Typography>
          </Grid>
        </Grid>
        <Grid item sm={4} xs={12}>
          <SvgIcon
            component={LogoIcon}
            viewBox="0 0 200 100"
            width="100%"
            height="100%"
            className={classes.svgSize}
          />
        </Grid>
        <Typography
          align="center"
          variant="h4"
          component="p"
          className={classes.gridItemMobile}
        >
          {telephone.main}
        </Typography>
        {telephone.additional === "null" ?
          null
          :
          <Typography
            align="center"
            variant="h4"
            component="p"
            className={classes.gridItemMobile}
          >
            {telephone.additional}
          </Typography>
        }
        <Divider className={classes.divider} />

        <Grid item container direction="column" className={classes.gridItem}>
          <Grid item>
            © {year_birth}{" "}{abbreviated_name} ИНН {inn} ОГРН {ogrn}
          </Grid>
          <Grid item>
            <Link to={PolicyPdf} target="_blank"><span className={classes.link}>Политика обработки и защиты персональных данных</span></Link>
          </Grid>
        </Grid>
      </Grid>
    </BlockContent>
  );
}
