import React from "react";
import BorderForComponent from "./borderForComponent";
import ButtonComponent from "./button";
import { ReactComponent as DethIcon } from "../static/svg/dept.svg";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  content: {
    padding: "40px"
  },
  card: {
    marginBottom: "50px"
  },
  cardContent: {
    textAlign: "left"
    // padding: "0rem 5rem 0rem 5rem"
  },
  blockText: {
    // margin: "20px 0px 20px 0px",
    padding: "20px 0px 20px 0px",
    textAlign: "left"
  },
  blockText_title: {
    fontSize: "1.8rem",
    fontWeight: "bold",
    color: "#F2C807"
  },
  blockText_description: {
    color: "#484D62",
    fontSize: "1rem"
  },
  blockContent: {
    // width: "70%",
    // margin: "0 auto"
  },
  buttonOrandge: {
    backgroundColor: "#DB6825",
    color: "#EBF0F5",
    fontSize: "12px"
  },
  blockImg: {
    width: "100%",
    height: "100%"
  },
  blockLeft: {
    order: 2
  },
  blockRight: {
    order: 1,
    margin: "30px auto",
    [theme.breakpoints.up("sm")]: {
      order: 2
    }
  },
  blockStock: {
    order: "3"
  }
}));

export default function Slider() {
  const classes = useStyles();
  return (
    <BorderForComponent>
      <Grid container alignItems="center" className={classes.card}>
        <Grid item xs={12} sm={6} className={classes.blockLeft}>
          <div className={classes.cardContent}>
            <div className={classes.blockContent}>
              <div className={classes.blockText}>
                <p className={classes.blockText_title}>
                  Итак, по уши в долгах — что же теперь делать?
                </p>
              </div>
              <div className={classes.blockText}>
                <p className={classes.blockText_description}>
                  Сложно ли закрыть долги? Какие действия необходимо предпринять? Как получить скидку? Есть ли жизнь после долгов?
                </p>
              </div>
              <div>
                <Link to="/contact"><ButtonComponent size="medium" title="Узнать подробнее" /></Link>
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} className={classes.blockRight}>
          {/* <img src="/images/dept.png" alt="dept" className={classes.blockImg} /> */}
          <DethIcon className={classes.blockImg} />
        </Grid>
        {/* <Grid item sm={12} className={classes.blockStock}>
          <BlockStock />
        </Grid> */}
      </Grid>
    </BorderForComponent>
  );
}
