import React from "react";
import { contentItemClient } from "./servicesClient/dataPages";
import ClientAllServices from "../components/clientServicesAll";
import BlockContent from "../components/blockContent";
import ClientServicesItem from "./clientServicesItem";
import { makeStyles } from "@material-ui/core/styles";
import DebtContent from "./servicesClient/debt";
import PayContent from "./servicesClient/pay";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { Switch, Route, Link, useRouteMatch } from "react-router-dom";
import { servicesList } from "../servicesList";
import { ReactComponent as CalenderIcon } from "../static/svg/calenderIcon.svg";
import { ReactComponent as PayIcon } from "../static/svg/payIcon.svg";
import { ReactComponent as SaleIcon } from "../static/svg/saleIcon.svg";
import { ReactComponent as SearchIcon } from "../static/svg/searchIcon.svg";
import { ReactComponent as Handshake } from "../static/svg/handshake.svg";
import { ReactComponent as LegalServices } from "../static/svg/legal_services.svg";
import { ReactComponent as Phone } from "../static/svg/phone.svg";
import { ReactComponent as CounterpartyCheck } from "../static/svg/counterparty_check.svg";
import { ReactComponent as Reference } from "../static/svg/reference.svg";

const useStyles = makeStyles((theme) => ({
  content: {
    // backgroundColor: "#F3F4F4",
    borderTop: "1px solid #D0D7DD"
  },
  contentItem: {
    margin: "20px",
    width: "200px",
    "&:hover": {
      boxShadow: "1px 1px 12px 0px rgb(72 77 98 / 17%)"
    },
    [theme.breakpoints.down("xs")]: {
      minWidth: "160px"
    }
  },
  contentItem_p: {
    height: "100%",
    padding: "30px",
    boxSizing: "border-box",
    border: "1px solid #F3F4F4"
  },
  contentItem__icon: {
    padding: "20px"
  }
}));
export default function ClientServices() {
  const classes = useStyles();
  return (
    <BlockContent title="Услуги" bigFont className={classes.content}>
      <Grid container wrap="wrap" justify="center">
        <Switch>
          <Route exact path="/client" component={ClientAllServices} />
          <Route path="/client/:name" component={ClientItemService} />
        </Switch>
      </Grid>
    </BlockContent>
  );
}

function ClientItemService() {
  const { url, params } = useRouteMatch();

  function get(name) {
    const isItem = (el) => el.url === name;
    return servicesList.find(isItem);
  }
  const item = get(params.name);
  if (!item) {
    return <div>Страница не найдена</div>;
  }
  return (
    <ClientServicesItem
      title={item.name}
      url={url}
      img2={item.img2}
      componentContent={contentItemClient[params.name]}
    />
  );
}