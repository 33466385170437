export const options = {
    year_birth: "2021",
    full_name_company: "Общество с ограниченной ответственностью «Хитен»",
    abbreviated_name: "ООО Коллекторское агентство «Хитен»",
    name_company: "Хитен",
    address: {
        postcode: "620026",
        region: "Свердловская",
        sity: "Екатеринбург",
        street: "Куйбышева",
        house: "60",
        office: "18А"
    },
    legal_address: "620026, Свердловская обл., г. Екатеринбург, ул. Куйбышева 60, оф. 18А",
    mailing_address: "620026, Свердловская обл., г. Екатеринбург, ул. Куйбышева 60, оф. 18А",
    inn: "6685140617",
    kpp: "668501001",
    ogrn: "1176658093963",
    director: "",
    email: "kahiten@mail.ru",
    // telephone: ["000-00-00", "111-11-11"],
    telephone: {
        main: "+7 967-908-29-57",
        additional: "null"
    }
}