import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ButtonComponent from "../../components/button";
import {ReactComponent as LegalServicesIcon} from "../../static/svg/legal_services.svg";
import {ReactComponent as CallCentreIcon} from "../../static/svg/call-centre.svg";
import {ReactComponent as EmailBorder} from "../../static/svg/emailBorder.svg";
import {options} from "../../config";

const useStyles = makeStyles((theme) => ({
  main: {
    // textAlign: "left"
  },
  mainTitle: {
    padding: "10px",
    background: "#F3F4F4",
    color: "#484d62",
    margin: "20px 0px 10px 0px",
    textAlign: "center"
  },
  mainItem: {
    margin: "10px"
  },
  mainDescription: {
    margin: "20px auto"
  },
  list: {
    padding: "20px"
  },
  titleBlock: {
    color: "#484D62",
    fontSize: "2rem",
    fontFamily: "Segoe UI, sans-serif",
    fontWeight: "bold",
    padding: "0px 0px 30px 20px"
  },
  linkButton: {
    width: "100%",
    display: "block",
    textAlign: "center"
  }
}
))

export default function LegalServicesContent({title, url, img2}) {
  const classes = useStyles();
  const {abbreviated_name, telephone, email} = options;
  return (
    <>
      <Grid item container className={classes.main}>
        <Grid item xs className={classes.mainItem}>
          <LegalServicesIcon height="100px" />
          {/* <ButtonComponent fullWidth title="Войти" /> */}
          <Typography align="left" className={classes.mainDescription}>
            {abbreviated_name} готово оказать помощь по
            административным делам, защите прав, разрешению споров, взысканию и
            другим направлениям. Задача будет решена профессионально, сэкономив
            ваши деньги, время и нервы.
          </Typography>
          <Typography align="left" className={classes.mainDescription}>
            Наше агентство использует индивидуальный подход к каждой ситуации.
            Осуществляем представительство в суде, взаимодействуя с
            государственными органами и контролируя ход событий. Наши клиенты
            платят только за результат, что само по себе является веским
            доказательством профессионализма наших сотрудников.
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs className={classes.mainItem}>
        <CallCentreIcon height="200px" />
        <Typography className={classes.mainTitle}>
          Телефон: {telephone.main}
        </Typography>
        <ButtonComponent
          fullWidth
          title={<a href={`tel:${telephone.main}`} className={classes.linkButton}>Позвонить</a>}
        />
      </Grid>
      <Grid item xs className={classes.mainItem}>
        <EmailBorder height="200px" />
        <Typography className={classes.mainTitle}>
          E-mail: {email}
        </Typography>
        <ButtonComponent
          fullWidth
          title={<a href={`mailto:${email}`} className={classes.linkButton}>Написать</a>}
        />
      </Grid>
    </>
  );
}
